import React from "react";

export function BurgerIcon() {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 1000"
  >
    <path
      d="M895.2,57.4H104.8c-52.2,0-94.8,42.7-94.8,94.8s42.7,94.8,94.8,94.8h790.3c52.2,0,94.8-42.7,94.8-94.8S947.3,57.4,895.2,57.4z"/>
    <path
      d="M895.2,405.2H104.8C52.7,405.2,10,447.8,10,500c0,52.2,42.7,94.8,94.8,94.8h790.3c52.2,0,94.8-42.7,94.8-94.8C990,447.8,947.3,405.2,895.2,405.2z"/>
    <path
      d="M895.2,752.9H104.8c-52.2,0-94.8,42.7-94.8,94.8c0,52.2,42.7,94.8,94.8,94.8h790.3c52.2,0,94.8-42.7,94.8-94.8C990,795.6,947.3,752.9,895.2,752.9z"/>
  </svg>;
}
